

.loader-main{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
    z-index: 999999;
    background-color: $background;
    .loader-container {
        position: relative;
        width: 18rem;
        height: 18rem;
        background-color: #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        .dot {
            position: absolute;
            width: 20px;
            height: 20px;
            border-radius: 50%;
        }
        
        .dot-1 {
            background-color: #3db5e9;
            z-index: 4;
            animation: moveDot1 2s infinite ease;
        }
        
        .dot-2 {
            background-color: #1de1c0;
            z-index: 3;
            animation: moveDot2 2s infinite ease;
        }
        
        .dot-3 {
            background-color: #005cff;
            z-index: 2;
            animation: moveDot3 2s infinite ease;
        }
        
        .dot-4 {
            background-color: #03007f;
            z-index: 1;
            animation: moveDot4 2s infinite ease;
        }
    }
}


.fixed-icons{
    position: fixed;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999999;
    .icon-ul{
        .icon-li{
            &:not(:last-child){
                margin-bottom: 16px;
            }
            .icon-otr{
                width: 52px;
                height: 52px;
                border-radius: 100%;
                background: $background;
                box-shadow: inset 5px 5px 15px #D1D9E6, inset -5px -5px 15px #FFFFFF;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: .4s;
                &:hover{
                    background-color: #3fbb4e;
                    box-shadow: none;
                    .icon-inr{
                        color: $White;
                    }
                }
                .icon-inr{
                    font-size: 24px;
                    color: $black-4;
                    transition: .3s;
                }
            }
        }
    }
}



/*=========================================
        Navbar Hero Start Here
=========================================*/

.hero-main{
    background-image: url('/assets/img/hero-bg.jpg');
    background-attachment: fixed;
    background-size: cover;
    position: relative;
    background-color: $background;
    .container{
        @include breakpoint($secreen-lg){
            width: 100%;
            max-width: 100%;
        }
        .nave{
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            padding: 36px 0;
            @include breakpoint($secreen-max-md){
                display: none;
            }
            .ul-items{
                display: flex;
                align-items: center;
                .li-items{
                    position: relative;
                    cursor: pointer;
                    &:hover{
                        .menu-drop-otr{
                            opacity: 1;
                            visibility: visible;
                            top: 12px;
                        }
                    }
                    &:not(:last-child)
                    {
                        margin-right: 40px;
                    }
                    .a-items{
                        color: $black-65;
                        transition: .3s;
                        &:hover{
                            color: $black;
                        }
                    }
                    .menu-drop-otr{
                        position: absolute;
                        left: -70px;
                        top: 100px;
                        padding-top: 24px;
                        opacity: 0;
                        visibility: hidden;
                        transition: .3s;
                        z-index: 9999;
                        .drop-ul{
                            width: 250px;
                            padding: 16px 16px;
                            background-color:  $white-Light;
                            border-radius: 4px;
                            z-index: 9999;
                            .drop-li{
                                position: relative;
                                &:hover
                                .other-drop-otr{
                                    opacity: 1;
                                    visibility: visible;
                                }
                                &:not(:last-child){
                                    margin-bottom: 12px;
                                }
                                .drop-a{
                                    position: relative;
                                    display: flex;
                                    align-items: center;
                                    color:   $black-65;
                                    cursor: pointer;
                                    transition: .3s;
                                    &:hover{
                                        color: $black;
                                    }
                                }
                                .other-drop-otr{
                                    position: absolute;
                                    right: 230px;
                                    top: -50px;
                                    margin-top: 34px;
                                    opacity: 0;
                                    visibility: hidden;
                                    border-right: 1px solid $black-4;
                                    transition: .3s;
                                    z-index: 9999;
                                    .other-drop-ul{
                                        width: 300px;
                                        padding: 16px 24px;
                                        background-color: $white-Light;
                                        border-radius: 4px;
                                        z-index: 9999;
                                        .other-drop-li{
                                            &:not(:last-child){
                                                margin-bottom: 12px;
                                            }
                                            .other-drop-a{
                                                position: relative;
                                                display: flex;
                                                align-items: center;
                                                color: $black-65;
                                                transition: .3s;
                                                &:hover{
                                                    color: $black;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .logo-otr{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        .mobile-header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 36px 0;
            @include breakpoint($secreen-min-lg){
                display: none;
            }
            @include breakpoint($secreen-xs){
                padding: 20px 0;
            }
             .mobile-logo{
                 width: 100px;
             }
             .burger-icon{
                .icon-otr{
                    width: 52px;
                    height: 52px;
                    border-radius: 100%;
                    background: $background;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &:focus{
                        box-shadow: none;
                    }
                    .icon-innr{
                        font-size: 24px;
                        color: $black-4;
                    }
                }
             }
             .offcanvas{
                .offcanvas-header{
                    .logo-icon{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 360px;
                        .img-otr{
                            .img-innr{
                                width: 100px;
                            }
                        }
                        .icon-otr{
                            width: 52px;
                            height: 52px;
                            border-radius: 100%;
                            background: $background;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .icon-innr{
                                font-size: 24px;
                                color: $black-4;
                            }
                        }
                        .icon-2{
                            padding-left: 16px;
                            .icon-innr2{
                                font-size: 24px;
                                color: $black-4;
                            }
                        }
                    }
                }
                 .offcanvas-body{
                     padding-top: 48px;
                     padding-bottom: 30px;
                    .menu-otr{
                        .menu-innr{
                            .ul-items{
                                .li-items{
                                    &:not(:last-child){
                                        margin-bottom: 12px;
                                    }
                                    &:hover{
                                        .a-items{
                                            color: $black;
                                        }
                                    }
                                    .a-items{
                                        color: $black-65;
                                        transition: .4s;
                                    }
                                    .menu-drop-otr{
                                        .drop-ul{
                                            .drop-li{
                                                .drop-a{
                                                    color: $black-65;
                                                    transition: .3s;
                                                    &:hover{
                                                        color: $black;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .ul-items-small{
                                padding-top: 36px;
                                padding-bottom: 46px;
                                .li-items-small{
                                    &:not(:last-child){
                                        margin-bottom: 8px;
                                    }
                                    &:hover{
                                        .a-items-small{
                                            color: $black;
                                        }
                                    }
                                    .a-items-small{
                                        color: $black-65;
                                        transition: .4s;
                                    }
                                    .menu-drop-otr{
                                        .drop-ul{
                                            .drop-li{
                                                .drop-a{
                                                    color: $black-65;
                                                    transition: .3s;
                                                    &:hover{
                                                        color: $black;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .col-innr{
                                box-shadow: inset 9px 9px 15px #D1D9E6, inset -9px -9px 15px #FFFFFF;
                                padding: 18px 0px;
                               
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                transition: .4s;
                                &:hover{
                                    box-shadow: $Box-Shadow;
                                }
                                .icon-otr{
                                    .icon-innr{
                                        color: $black-4;
                                        font-size: 24px;
                                    }
                                    .icon-innr2{
                                        font-size: 32px;
                                    }
                                }
                                .contact-info{
                                    color: $black;
                                    font-feature-settings: 'pnum' on, 'lnum' on;
                                }
                            }
                        }
                    }
                 }
             }
            
        }
        .hero-inr{
            padding: 72px 0 128px 0;
            position: relative;
            z-index: 10;
            @include breakpoint($secreen-xs){
                padding: 40px 0 70px 0;
            }
            &::after{
                content: "";
                position: absolute;
                left: 50%;
                bottom: 128px;
                transform: translateX(-50%);
                width: 730px;
                height: 730px;
                border-radius: 100%;
                background-color: $background;
                box-shadow: inset 18px 18px 30px #D1D9E6, inset -18px -18px 30px #FFFFFF;
                z-index: -1;
                @include breakpoint($secreen-xxl){
                    width: 650px;
                    height: 650px;
                }
                @include breakpoint($secreen-max-md){
                    width: 400px;
                    height: 400px;
                }
                @include breakpoint($secreen-xs){
                    display: none;
                }
            }
            .heading{
                text-align: center;
                padding: 0 0 60px 0;
                @include breakpoint($secreen-xs){
                    padding: 0 0 30px 0;
                }
            }
            .hero-img{
                width: 100%;
            }
            .explore-btn{
                text-align: center;
                position: absolute;
                width: 150px;
                bottom: 64px;
                left: 50%;
                transform: translateX(-50%);
                margin: 0 auto;
                @include breakpoint($secreen-xs){
                    display: none;
                }
                .arrow-img{
                    position: relative;
                    top: -20px;
                }
            }
        }
    }
    .marquee{
        position: absolute;
        top: 32%;
        .marquee-ul{
            display: flex;
            align-items: center;
            position: relative;
            left: -1700px;
            .marquee-li{
                color: $black;
                opacity: 0.07;
                font-size: 96px;
                font-family: $font-family-Syne-ExtraBold;
                &:not(:last-child){
                    padding-left: 50px;
                }
            }
        }
    }
}

/*=========================================
        Navbar Hero End Here
=========================================*/

/*=========================================
        Catalouge Start Here
=========================================*/

.catalouge{
    padding-top: 128px;
    padding-bottom: 128px;
    @include breakpoint($secreen-xs){
        padding: 80px 0;
    }
    .container{
        .heading-main{
            color: $black-1;
            text-align: center;
            margin-bottom: 72px;
            @include breakpoint($secreen-max-md){
                margin-bottom: 48px;
            }
        }
        .row-custom{
            .swiper-otr{
                .mySwiper {
                    .swiper-wrapper{
                        height: auto;
                        .swiper-slide{
                            .img-fluid{
                                width: 100%;
                            }
                        }
                    }
                    .swiper-pagination {
                        padding: 8px;
                        background: rgba(255, 255, 255, 0.25);
                        backdrop-filter: blur(15px);
                        color: white;
                        bottom: 24px;
                        width: auto;
                        left: 50%;
                        transform: translateX(-50%);
                        .swiper-pagination-bullet{
                            background-color: #fff;
                            opacity: 1;
                        }
                        .swiper-pagination-bullet-active{
                            background-color: black;
                        }
                    }
                  
                }
                .heading-box{
                    padding: 0;
                    background-color: transparent;
                    border: none;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 auto;
                    padding-top: 28px;
                    padding-bottom: 37px;
                }
                .modal-otr{
                    .modal-dialog-custom{
                        max-width: 1140px;
                        position: relative;
                        .modal-content-custom{
                            .icon-otr{
                                position: absolute;
                                top: 24px;
                                right: 24px;
                                z-index: 9999;
                                &:hover{
                                    .icon{
                                        color: $black;
                                    }
                                }
                                @include breakpoint($secreen-xs){
                                    top: 2px;
                                    right: 4px;
                                }
                              
                                .icon{
                                    font-size: 36px;
                                    color: $black-4;
                                    transition: .4s;
                                    cursor: pointer;
                                    @include breakpoint($secreen-xs){
                                       font-size: 25px;
                                    }
                                }
                            }
                            .modal-body-custom{
                                padding: 0px;
                                .pop-up{
                                    .row-custom{
                                        padding: 72px;
                                        @include breakpoint($secreen-xs){
                                            padding: 40px 15px;
                                        }
                                        @include breakpoint($secreen-xs){
                                            flex-direction: column-reverse;
                                        }
                                        .swiper-otr{
                                            @include breakpoint($secreen-xs){
                                                padding-top: 30px;
                                            }
                                            .mySwiper {
                                                .swiper-wrapper{
                                                    height: auto;
                                                    .swiper-slide{
                                                        .img-fluid{
                                                            width: 100%;
                                                        }
                                                    }
                                                }
                                                .swiper-pagination {
                                                    padding: 8px;
                                                    background: rgba(255, 255, 255, 0.25);
                                                    backdrop-filter: blur(15px);
                                                    color: white;
                                                    bottom: 24px;
                                                    width: auto;
                                                    left: 50%;
                                                    transform: translateX(-50%);
                                                    .swiper-pagination-bullet{
                                                        background-color: #fff;
                                                        opacity: 1;
                                                    }
                                                    .swiper-pagination-bullet-active{
                                                        background-color: black;
                                                    }
                                                }
                                              
                                            }
                                        }
                                        .content{
                                            padding-left: 69px;
                                            @include breakpoint($secreen-max-md){
                                                padding-left: 10px;
                                            }
                                            .content-innr{
                                                display: flex;
                                                flex-direction: column;
                                                justify-content: space-between;
                                                height: 100%;
                                                .content-div{
                                                  
                                                    @include breakpoint($secreen-max-md){
                                                        padding-top: 0px;
                                                        padding-bottom: 0px;
                                                    }
                                                   
                                                    .heading-H2{
                                                        color: $black;
                                                        text-align: start;
                                                        @include breakpoint($secreen-max-md){
                                                            margin-bottom: 0px;
                                                            font-size: 35px;
                                                        }
                                                    }
                                                    .ul-content{
                                                        padding-top: 28px;
                                                        @include breakpoint($secreen-max-md){
                                                            padding-top: 15px;
                                                        }
                                                        @include breakpoint($secreen-xs){
                                                            margin-bottom: 20px;
                                                        }
                                                        .content1{
                                                            display: flex;
                                                            &:not(:last-child){
                                                                padding-bottom: 12px;
                                                            }
                                                            
                                                           
                                                            .txt1{
                                                                color: $black;
                                                            }
                                                            .txt2{
                                                                color: $black-4;
                                                                padding-left: 5px;
                                                            }
                                                        }
                                                    }
                                                   
                                                }
                                                .action-otr{
                                                    display: flex;
                                                    .action-innr{
                                                        padding: 18px 40px;
                                                        background-color: $background;
                                                        color: black;
                                                        font-size: 16px;
                                                        line-height: 24px;
                                                        font-family: "Syne-Bold";
                                                        box-shadow: inset 5px 5px 15px #D1D9E6, inset -5px -5px 15px #FFFFFF;
                                                    }
                                                }
                                            }
                                          
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .on-mobile{
                @include breakpoint($secreen-max-md){
                    display: none;
                }
            }
        }
        .action{
            padding-top: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

/*=========================================
        Catalouge end Here
=========================================*/

/*=========================================
        Satisfaction Start Here
=========================================*/

.satisfaction{
    overflow: hidden;
    background-color: $background;
    padding-top: 128px;
    padding-bottom: 128px;
    @include breakpoint($secreen-xs){
        padding: 80px 0;
    }
    .container{
        .heading-H2{
            color: $black;
            text-align: center;
            padding-bottom: 72px;
            font-feature-settings: 'pnum' on, 'lnum' on;
            @include breakpoint($secreen-max-md){
                padding-bottom: 48px;
            }
        }
        .mySwiper{
            overflow: visible;
            .swiper-wrapper{
                height: auto;
                .swiper-slide{
                    .main{
                        padding: 72px;
                        background-color: #E6E9EF;
                        box-shadow: inset 5px 5px 15px #D1D9E6, inset -5px -5px 15px #FFFFFF;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        overflow: visible  !important;
                        @include breakpoint($secreen-max-md){
                            flex-direction: column;
                            padding: 40px;
                        }
                        @include breakpoint($secreen-xs){
                            padding: 40px 20px;
                        }
                        .img-otr{
                            padding-right: 109px;
                            @include breakpoint($secreen-max-md){
                                padding-right: 0px;
                                padding-bottom: 34px;
                            }
                            .img-innr{
                                width: 170px;
                                height: 170px;
                                object-fit: cover;
                            }
                        }
                        .content{
                            @include breakpoint($secreen-max-md){
                               text-align: center;
                            }
                            .heading-H3{
                                padding-bottom: 24px;
                                color: $black-1;
                                @include breakpoint($secreen-max-md){
                                    padding-bottom: 24px;
                                }
                            }
                            .txt1{
                                color: $black-65;
                                padding-bottom: 4px;
                            }
                            .txt2{
                                color: $black-4;
                            }
                        }
                    }
                }
            }
            .swiper-pagination {
                bottom: -53px;
                @include breakpoint($secreen-xs){
                    bottom: -20px;
                }
                .swiper-pagination-bullet{
                    background-color: #fff;
                    opacity: 1;
                }
                .swiper-pagination-bullet-active{
                    background-color: black;
                }
            }
            .swiper-button-prev{
                width: 52px;
                height: 52px;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                left: -95px;
                background: $background url("/assets/img/arrow-left-line.svg") no-repeat;
                background-position: center;
                box-shadow: inset 5px 5px 15px #D1D9E6, inset -5px -5px 15px #FFFFFF;
                transition: .4s;
                @include breakpoint($secreen-xxl){
                    left: -26px;
                }
                &::after{
                    display: none;
                }
                &:hover{
                    background: $White url("/assets/img/hover-pre.svg") no-repeat;
                    background-position: center;
                }
                @include breakpoint($secreen-max-md){
                    display: none;
                }
            }
            .swiper-button-next{
                width: 52px;
                height: 52px;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                right: -95px;
                background: $background url("/assets/img/arrow-right-line.svg") no-repeat;
                background-position: center;
                box-shadow: inset 5px 5px 15px #D1D9E6, inset -5px -5px 15px #FFFFFF;
                transition: .4s;
                @include breakpoint($secreen-xxl){
                    right: -26px;
                }
                @include breakpoint($secreen-max-md){
                    display: none;
                }
                &::after{
                    display: none;
                }
                &:hover{
                    background: $White url("/assets/img/right-hover.svg") no-repeat;
                    background-position: center;
                }
            }
        }
    }
}
.bg-light {
    .swiper-pagination-bullet{
        background-color: $background !important;
    }
    .swiper-pagination-bullet-active{
        background-color: $black !important;
    }
}

/*=========================================
        Satisfaction Start Here
=========================================*/

/*------------------------------------------
 Popular Section Start Here
-------------------------------------------*/

.popular-section{
    padding-top: 128px;
    padding-bottom: 128px;
    @include breakpoint($secreen-xs){
        padding: 80px 0 40px 0;
    }
    .container{
        .heading-H2{
            color: $black;
            text-align: center;
            padding-bottom: 72px;
            @include breakpoint($secreen-max-md){
                padding-bottom: 48px;
            }
        }
        .row-custom{
            .slider-otr{
                .heading-box{
                    padding: 0;
                    background-color: transparent;
                    border: none;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 auto;
                    padding-top: 28px;
                    padding-bottom: 37px;
                }
                .modal-otr{
                    .modal-dialog-custom{
                        max-width: 1140px;
                        position: relative;
                        .modal-content-custom{
                            .icon-otr{
                                position: absolute;
                                top: 24px;
                                right: 24px;
                                z-index: 9999;
                                &:hover{
                                    .icon{
                                        color: $black;
                                    }
                                }
                                @include breakpoint($secreen-xs){
                                    top: 2px;
                                    right: 4px;
                                }
                            
                                .icon{
                                    font-size: 36px;
                                    color: $black-4;
                                    transition: .4s;
                                    cursor: pointer;
                                    @include breakpoint($secreen-xs){
                                    font-size: 25px;
                                    }
                                }
                            }
                            .modal-body-custom{
                                padding: 0px;
                                .pop-up{
                                    .row-custom{
                                        padding: 72px;
                                        @include breakpoint($secreen-xs){
                                            padding: 40px 15px;
                                        }
                                        @include breakpoint($secreen-xs){
                                            flex-direction: column-reverse;
                                        }
                                        .swiper-otr{
                                            @include breakpoint($secreen-xs){
                                                padding-top: 30px;
                                            }
                                            .mySwiper {
                                                .swiper-wrapper{
                                                    height: auto;
                                                    .swiper-slide{
                                                        .img-fluid{
                                                            width: 100%;
                                                        }
                                                    }
                                                }
                                                .swiper-pagination {
                                                    padding: 8px;
                                                    background: rgba(255, 255, 255, 0.25);
                                                    backdrop-filter: blur(15px);
                                                    color: white;
                                                    bottom: 24px;
                                                    width: auto;
                                                    left: 50%;
                                                    transform: translateX(-50%);
                                                    .swiper-pagination-bullet{
                                                        background-color: #fff;
                                                        opacity: 1;
                                                    }
                                                    .swiper-pagination-bullet-active{
                                                        background-color: black;
                                                    }
                                                }
                                              
                                            }
                                        }
                                        .content{
                                            padding-left: 69px;
                                            @include breakpoint($secreen-max-md){
                                                padding-left: 10px;
                                            }
                                            .content-innr{
                                                display: flex;
                                                flex-direction: column;
                                                justify-content: space-between;
                                                height: 100%;
                                                .content-div{
                                                  
                                                    @include breakpoint($secreen-max-md){
                                                        padding-top: 0px;
                                                        padding-bottom: 0px;
                                                    }
                                                   
                                                    .heading-H2{
                                                        color: $black;
                                                        text-align: start;
                                                        padding-bottom: 0;
                                                        @include breakpoint($secreen-max-md){
                                                            margin-bottom: 0px;
                                                            font-size: 35px;
                                                        }
                                                    }
                                                    .ul-content{
                                                        padding-top: 28px;
                                                        @include breakpoint($secreen-max-md){
                                                            padding-top: 15px;
                                                        }
                                                        @include breakpoint($secreen-xs){
                                                            margin-bottom: 20px;
                                                        }
                                                        .content1{
                                                            display: flex;
                                                            &:not(:last-child){
                                                                padding-bottom: 12px;
                                                            }
                                                            
                                                           
                                                            .txt1{
                                                                color: $black;
                                                            }
                                                            .txt2{
                                                                color: $black-4;
                                                                padding-left: 5px;
                                                            }
                                                        }
                                                    }
                                                   
                                                }
                                                .action-otr{
                                                    display: flex;
                                                    .action-innr{
                                                        padding: 18px 40px;
                                                        background-color: $background;
                                                        color: black;
                                                        font-size: 16px;
                                                        line-height: 24px;
                                                        font-family: "Syne-Bold";
                                                        box-shadow: inset 5px 5px 15px #D1D9E6, inset -5px -5px 15px #FFFFFF;
                                                    }
                                                }
                                            }
                                          
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .swiper{
                    height: auto !important;
                    margin-bottom: 24px;
                    .swiper-wrapper{
                        .swiper-slide{
                            .img{
                                width: 100%;
                                height: 267px;
                                object-fit: cover;
                            }
                        }
                    }
                }
                .swiper{
                    height: auto !important;
                    .swiper-wrapper{
                        height: auto !important;
                        .swiper-slide{
                            .img2{
                                width: 100%;
                                height: 73px;
                                object-fit: cover;
                            }
                        }
                    }
                }
                .heading-H3{
                    color: $black;
                    text-align: center;
                    padding: 0 0 40px 0;
                    font-feature-settings: 'pnum' on, 'lnum' on;
                }
            }
            .for-mobile{
                @include breakpoint($secreen-max-md){
                    display: none;
                }
            }
        }
        .action-otr{
            display: flex;
            margin: 32px 0 0 0;
            .btn-theme{
                padding-top: 72px;
            }
        }
        .action-otr1{
            display: flex;
            justify-content: center;
            padding-top: 72px;
        }
    }
}

/*------------------------------------------
 Popular Section End Here
-------------------------------------------*/

/*------------------------------------------
 Footer  Start Here
-------------------------------------------*/

.footer{
    background-color: $background;
    .main{
        padding: 120px 0 136px 0;
        @include breakpoint($secreen-xs){
            padding: 80px 0;
        }
        .container{
            .ul-items{
                padding-bottom: 48px;
                display: flex;
                justify-content: space-between;
                gap: 50px;
                @include breakpoint($secreen-max-md){
                    flex-wrap: wrap;
                    justify-content: initial;
                    gap: 30px;
                    padding-bottom: 20px;
                }
                .li-items{
                    &:hover{
                        .a-items{
                            color: $black;
                        }
                    }
                    .a-items{
                        color: $black-4;
                        transition: .4s;
                    }
                }
            }
            .row-custom{
                @include breakpoint($secreen-max-md){
                    flex-direction: column;
                }
                .col-otr{
                    padding-top: 24px;
                  
                    .col-innr{
                        box-shadow: inset 9px 9px 15px #D1D9E6, inset -9px -9px 15px #FFFFFF;
                        padding: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: .4s;
                        @include breakpoint($secreen-max-md){
                            flex-direction: column;
                        }
                        @include breakpoint($secreen-xs){
                            padding: 24px;
                        }
                        &:hover{
                            box-shadow: $Box-Shadow;
                        }
                        .icon-otr{
                            padding-right: 18px;
                            .icon-innr{
                                color: $black-4;
                                font-size: 32px;
                            }
                            .icon-innr2{
                                font-size: 32px;
                                color: $whatsapp-color;
                            }
                        }
                        .contact-info{
                            color: $black;
                            font-feature-settings: 'pnum' on, 'lnum' on;
                            text-align: center;
                            line-break: anywhere;
                        }
                    }
                }
            }
        }
    }
    .copy-main{
        background-color: $White;
        .container{
            .try{
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: $White;
                padding: 33px 0;
                width: 100%;
                position: relative;
                @include breakpoint($secreen-max-md){
                    flex-direction: column;
                }
                .logo-footer{
                    .logo-inr{
                        @include breakpoint($secreen-max-md){
                            width: 100px;
                        }
                    }
                }
                .copy-right{
                    color: $black-65;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    @include breakpoint($secreen-max-md){
                        text-align: center;
                        position: relative;
                        left: 0;
                        top: 0;
                        transform: translate(0);
                        padding: 20px 0;
                    }
                    .site-name{
                        display: inline-block;
                        color: $black-65;
                        transition: .3s;
                        &:hover{
                            color: $black;
                        }
                    }
                }
                .dot{
                    color: $black-4;
                }
                .links{
                    color: $black-4;
                    transition: .3s;
                    position: relative;
                    &:hover{
                        color: $black;
                    }
                    &:hover::after{
                        background-color: $black;
                    }
                    &::after{
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        height: 1px;
                        background-color: $black-4;
                        transition: .3s;
                    }
                }
            }
        }
    }
}

/*------------------------------------------
 Footer  End Here
-------------------------------------------*/