
/*=========================================
        Navbar Hero Start Here
=========================================*/

.hero-main{
    background-image: url('/assets/img/hero-bg.jpg');
    background-attachment: fixed;
    background-size: cover;
    position: relative;
    background-color: $background;
    .container{
        @include breakpoint($secreen-lg){
            width: 100%;
            max-width: 100%;
        }
        .nave{
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            padding: 36px 0;
            @include breakpoint($secreen-max-md){
                display: none;
            }
            .ul-items{
                display: flex;
                align-items: center;
                .li-items{
                    &:not(:last-child)
                    {
                        margin-right: 40px;
                    }
                    .a-items{
                        color: $black-65;
                        transition: .3s;
                        &:hover{
                            color: $black;
                        }
                    }
                }
            }
            .logo-otr{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        .mobile-header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 36px 0;
            @include breakpoint($secreen-min-lg){
                display: none;
            }
            @include breakpoint($secreen-xs){
                padding: 20px 0;
            }
             .mobile-logo{
                 width: 100px;
             }
             .burger-icon{
                .icon-otr{
                    width: 52px;
                    height: 52px;
                    border-radius: 100%;
                    background: $background;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &:focus{
                        box-shadow: none;
                    }
                    .icon-innr{
                        font-size: 24px;
                        color: $black-4;
                    }
                }
             }
             .offcanvas{
                .offcanvas-header{
                    .logo-icon{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 360px;
                        .img-otr{
                            .img-innr{
                                width: 100px;
                            }
                        }
                        .icon-otr{
                            width: 52px;
                            height: 52px;
                            border-radius: 100%;
                            background: $background;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .icon-innr{
                                font-size: 24px;
                                color: $black-4;
                            }
                        }
                        .icon-2{
                            padding-left: 16px;
                            .icon-innr2{
                                font-size: 24px;
                                color: $black-4;
                            }
                        }
                    }
                }
                 .offcanvas-body{
                     padding-top: 48px;
                     padding-bottom: 30px;
                    .menu-otr{
                        .menu-innr{
                            .ul-items{
                                .li-items{
                                    &:not(:last-child){
                                        margin-bottom: 12px;
                                    }
                                    &:hover{
                                        .a-items{
                                            color: $black;
                                        }
                                    }
                                    .a-items{
                                        color: $black-65;
                                        transition: .4s
                                        ;
                                    }
                                }
                            }
                            .ul-items-small{
                                padding-top: 36px;
                                padding-bottom: 46px;
                                .li-items-small{
                                    &:not(:last-child){
                                        margin-bottom: 8px;
                                    }
                                    &:hover{
                                        .a-items-small{
                                            color: $black;
                                        }
                                    }
                                    .a-items-small{
                                        color: $black-65;
                                        transition: .4s
                                        ;
                                    }
                                }
                            }
                            .col-innr{
                                box-shadow: inset 9px 9px 15px #D1D9E6, inset -9px -9px 15px #FFFFFF;
                                padding: 18px 0px;
                               
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                transition: .4s;
                                &:hover{
                                    box-shadow: $Box-Shadow;
                                }
                                .icon-otr{
                                    .icon-innr{
                                        color: $black-4;
                                        font-size: 24px;
                                    }
                                    .icon-innr2{
                                        font-size: 32px;
                                    }
                                }
                                .contact-info{
                                    color: $black;
                                    font-feature-settings: 'pnum' on, 'lnum' on;
                                }
                            }
                        }
                    }
                 }
             }
            
        }
        .heading{
            text-align: center;
            padding: 36px 0 72px 0;
        }    
    }
}

/*=========================================
        Navbar Hero End Here
=========================================*/
