/*------------------------------------------
        Hero Start Here
-------------------------------------------*/


    .granite-section{
        .container{
            .row-granite{
                padding: 144px 0 144px 0;
                @include breakpoint($secreen-max-md){
                    padding: 72px 0 72px 0;
                }
                gap: 40px 0;
                .col-item-otr{
                    .col-item-inr{
                        &:hover
                        .name-otr{
                            .name{
                                color: $black;
                            }
                        }
                        .img-otr{
                            padding-bottom: 16px;
                            .img{
                                width: 100%;
                                height: 279px;
                                object-fit: cover;
                            }
                        }
                        .name-otr{
                           
                            .name{
                                text-align: center;
                                color:  $black-65;
                                transition: .3s;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
        }
    }