



/*------------------------------------------
    Section 2 start Here
-------------------------------------------*/

.about-main{
    .container{
        .row-custom{
            padding-top: 145px;
            @include breakpoint($secreen-max-md){
                flex-direction: column;
            }
            @include breakpoint($secreen-xs){
                padding-top: 80px;
            }
            align-items: center;
            .content-otr{
                @include breakpoint($secreen-max-md){
                    padding-bottom: 30px;
                }
                .content-innr{
                   
                    .heading-H2{
                        color: $black;
                    }
                    .heading-M{
                        color: $black-65;
                        padding-top: 28px;
                    }
                }
            }
            .mySwiper{
                .swiper-wrapper{
                    .swiper-slide{
                        .img-otr{
                            .img-innr{
                                background-color: $black05;
                                padding: 24px;
                            }
                        } 
                    }
                }
            } 
        }
    }
}

.about-main-2{
    .container{
        .row-custom2{
            margin-top: 145px;
            margin-bottom: 128px;
            @include breakpoint($secreen-max-md){
                margin-bottom: 40px;
                margin-top: 40px;
            }
            .content{
               @include breakpoint($secreen-xs){
                   padding-bottom: 30px;
               }

                .heading-H2{
                    color: $black;
                    padding-bottom: 28px;
                }
                .heading-M{
                    color: $black-65;
                }
            } 
        }
    }
}

/*------------------------------------------
    Section 2 End Here
-------------------------------------------*/


/*------------------------------------------
    Section 3 Start Here
-------------------------------------------*/

.Team-Section{
    padding-top: 128px;
    padding-bottom: 128px;
    background-image: url('/assets/img/hero-bg.jpg');
    background-color: $background;
    background-size: cover;
    @include breakpoint($secreen-xs){
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .container{
        .heading-H2{
            color: $black;
            text-align: center;
            padding-bottom: 72px;
            @include breakpoint($secreen-xs){
                padding-bottom: 30px;
            }
        }
        .row-custom{
            .col-box{
                text-align: center;
                @include breakpoint($secreen-max-md){
                    padding-bottom: 30px;
                }

                .img{
                    width: 100%;
                }
                .heading-H3{
                    color: $black;
                    padding-top: 24px;
                    padding-bottom: 8px;
                }

                .heading-S{
                    color: $black-4;
                }
            }
        }
    }
}

/*------------------------------------------
    Section 3 End Here
-------------------------------------------*/