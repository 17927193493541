@mixin site-transition {
    transition: all 0.5s ease-in-out;
    transition: 0.5s linear; /* vendorless fallback */
    -o-transition: 0.5s linear; /* opera */
    -ms-transition: 0.5s linear; /* IE 10 */
    -moz-transition: 0.5s linear; /* Firefox */
    -webkit-transition: 0.5s linear; /*safari and chrome */
}




@keyframes moveDot1 {
    from {
      transform: rotate(0deg);
      /* transform-origin: top left */
      transform-origin: -20px -20px;
      transform-style: preserve-3D;
    }
  
    to {
      transform: rotate(-360deg);
      /* transform-origin: top left */
      transform-origin: -20px -20px;
      transform-style: preserve-3D;
    }
  }
  
  @keyframes moveDot2 {
    from {
      transform: rotate(0deg);
      /* transform-origin: top right */
      transform-origin: 40px -20px;
      transform-style: preserve-3D;
    }
  
    to {
      transform: rotate(-360deg);
      /* transform-origin: top right */
      transform-origin: 40px -20px;
      transform-style: preserve-3D;
    }
  }
  
  @keyframes moveDot3 {
    from {
      transform: rotate(0deg);
      /* transform-origin: bottom left */
      transform-origin: -20px 40px;
      transform-style: preserve-3D;
    }
  
    to {
      transform: rotate(-360deg);
      /* transform-origin: bottom left */
      transform-origin: -20px 40px;
      transform-style: preserve-3D;
    }
  }
  
  @keyframes moveDot4 {
    from {
      transform: rotate(0deg);
      /* transform-origin: bottom right */
      transform-origin: 40px 40px;
      transform-style: preserve-3D;
    }
  
    to {
      transform: rotate(-360deg);
      /* transform-origin: bottom right */
      transform-origin: 40px 40px;
      transform-style: preserve-3D;
    }
  }