/*------------------------------------------
     Contact  Start Here
-------------------------------------------*/
    .contact{
        .container{
            .row-custom{
                padding-top: 128px;
                padding-bottom: 128px;
                justify-content: space-between;
                .content-otr{
                    @include breakpoint($secreen-max-md){
                        padding-bottom: 60px;
                    }
                    .content-innr{
                        .heading-H2{
                            color: $black;
                        }
                        .heading-M{
                            color: $black-65;
                            padding-top: 28px;
                            padding-bottom: 40px;
                        }
                        .input-otr{
                            border-bottom: 1px solid $black-4;
                            width: 100%;
                            &:hover{
                                border-bottom: 1px solid $black;
                            }
                            .input-innr{
                                width: 100%;
                                padding-bottom: 16px;
                                color: $black;
                                border: none;
                                &:focus{
                                    outline: none;
                                    box-shadow: none;
                                }
                                &::placeholder{
                                    color: $black-4;
                                }
                            }

                        }
                        .input-otr2{
                            margin-top: 32px;
                            margin-bottom: 32px;
                            border-bottom: 1px solid $black-4;
                            width: 100%;
                            &:hover{
                                border-bottom: 1px solid $black;
                            }
                            .input-innr{
                                width: 100%;
                                padding-bottom: 16px;
                                color: $black;
                                border: none;
                                &:focus{
                                    outline: none;
                                    box-shadow: none;
                                }
                                &::placeholder{
                                    color: $black-4;
                                }
                            }
                        }
                        .action-otr{
                            display: flex;
                            padding-top: 36px;
                            .btn-theme{
                                padding: 17px 40px;
                            }
                        }
                    }
                }
                .map-otr{

                }
            }
        }
    }
    

/*------------------------------------------
     Contact  End Here
-------------------------------------------*/