/*------------------------------------------
          pop-up start Here
-------------------------------------------*/

    .pop-up{
        .row-custom{
            padding: 72px;
            .swiper-otr{
                .mySwiper {
                    .swiper-wrapper{
                        height: auto;
                        .swiper-slide{
                            .img-fluid{
                                width: 100%;
                            }
                        }
                    }
                    .swiper-pagination {
                        padding: 8px;
                        background: rgba(255, 255, 255, 0.25);
                        backdrop-filter: blur(15px);
                        color: white;
                        bottom: 24px;
                        width: auto;
                        left: 50%;
                        transform: translateX(-50%);
                        .swiper-pagination-bullet{
                            background-color: #fff;
                            opacity: 1;
                        }
                        .swiper-pagination-bullet-active{
                            background-color: black;
                        }
                    }
                  
                }
            }
            .content{
                padding-left: 69px;
                .content-innr{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;
                    .content-div{
                        .heading-H2{
                            color: $black;
                        }
                        .ul-content{
                            padding-top: 28px;
                            .content1{
                                display: flex;
                                &:not(:last-child){
                                    padding-bottom: 12px;
                                }
                                
                               
                                .txt1{
                                    color: $black;
                                }
                                .txt2{
                                    color: $black-4;
                                    padding-left: 5px;
                                }
                            }
                        }
                       
                    }
                    .action-otr{
                        display: flex;
                        .action-innr{
                            padding: 18px 40px;
                            background-color: $background;
                            color: black;
                            font-size: 16px;
                            line-height: 24px;
                            font-family: "Syne-Bold";
                            box-shadow: inset 5px 5px 15px #D1D9E6, inset -5px -5px 15px #FFFFFF;
                        }
                    }
                }
              
            }
        }
    }

/*------------------------------------------
          pop-up End Here
-------------------------------------------*/