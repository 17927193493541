

/*------------------------------------------
        Inner Header Start Here
-------------------------------------------*/

.inner-header{
    background-image: url('/assets/img/hero-bg.jpg');
    background-size: cover;
    padding-top: 36px;
    padding-bottom: 104px;
    .container{
        .nave{
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            .ul-items{
                display: flex;
                align-items: center;
                .li-items{
                    &:not(:last-child)
                    {
                        margin-right: 40px;
                    }
                    .a-items{
                        color: $black-65;
                        transition: .3s;
                        &:hover{
                            color: $black;
                        }
                    }
                }
            }
            .logo-otr{
                position: absolute;
                top: 1px;
                left: 583px;
            }
        }
        .heading-H2{
            text-align: center;
            color: $black;
            padding-top: 100px;
        }
    }
}

/*------------------------------------------
        Inner Header End Here
-------------------------------------------*/