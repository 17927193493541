.process {
    padding: 144px 0 144px 0;
    @include breakpoint($secreen-max-md){
        padding: 72px 0 72px 0;
    }
    .container {
        .row-process1{
            padding-bottom: 24px;
            @include breakpoint($secreen-max-md){
                flex-direction: column-reverse;
            }
            .col-img-otr {
                @include breakpoint($secreen-max-md){
                    padding-top: 35px;
                }
                .img {
                    width: 100%;
                    height: 412px;
                    @include breakpoint($secreen-xs){
                        height: 200px;
                    }
                    object-fit: cover;
                }
            }
            .col-content-otr {
                .col-content-inr {
                    .heading {
                        @include breakpoint($secreen-max-md){
                            padding-top: 35px;
                        }
                    }

                    .desc {
                        padding-top: 8px;
                    }
                    .desc2{
                        padding-top: 12px;
                    }
                }
            }
        }
        .row-process2{
            padding-bottom: 24px;
            .col-img-otr {
                @include breakpoint($secreen-max-md){
                    padding-top: 35px;
                }
                .img {
                    width: 100%;
                    height: 412px;
                    @include breakpoint($secreen-xs){
                        height: 200px;
                    }
                    object-fit: cover;
                }
            }
            .col-content-otr {
                .col-content-inr {
                    .heading {}

                    .desc {
                        padding-top: 8px;
                    }
                }
            }
        }
        .row-process3{
            padding-bottom: 24px;
            align-items: center;
            .col-img-otr {
                .img {
                    width: 100%;
                    height: 412px;
                    @include breakpoint($secreen-xs){
                        height: 200px;
                    }
                    object-fit: cover;
                }
            }
            .col-content-otr {
                .col-content-inr {
                    .heading {
                        @include breakpoint($secreen-max-md){
                            padding-top: 35px;
                        }
                    }

                    .desc {
                        padding-top: 8px;
                    }
                    .desc2{
                        padding-top: 12px;
                    }
                }
            }
        }
    }
}