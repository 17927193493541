/*------------------------------------------
        Hero Start Here
-------------------------------------------*/

.hero1{
    background-color: $background;
    padding-top: 36px;
    padding-bottom: 104px;
    .container{
      .nave{
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
        .ul-items{
            display: flex;
            align-items: center;
            .li-items{
                &:not(:last-child)
                {
                    margin-right: 40px;
                }
                .a-items{
                    color: $black-65;
                }
            }
        }
        .logo-otr{
            position: absolute;
            top: 1px;
           left: 583px;
        }
      }
      .heading-H2{
          text-align: center;
          color: $black;
          padding-top: 90px;
      }
    }
}

/*------------------------------------------
    Hero End Here
-------------------------------------------*/
