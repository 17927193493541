
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
li {
    list-style: none;
}
a {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}

body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0px;
    padding: 0px;
}
@include breakpoint($secreen-max-sm){
    br{
        display: none;
    }
}

html {
    box-sizing: border-box;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}



ul,li {
    list-style: none;
    margin: 0;
    padding: 0;
}

a {
    display: block;
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}


.heading-H1{
    font-family: $font-family-Syne-Bold;
    font-size: $Font-size-72px;
    @include breakpoint($secreen-max-md){
        font-size: $Font-size-48px;
    }
    @include breakpoint($secreen-xs){
       font-size: 36px;
       line-height: 52px;
    }
}
.heading-H2{
    font-family: $font-family-Syne-Bold;
    font-size: $Font-size-48px;
    @include breakpoint($secreen-xs){
        font-size: 32px;
        line-height: 44px;
     }
   
}
.heading-H3{
    font-family: $font-family-Syne-Bold;
    font-size: $Font-size-24px;
    line-height: $line-height-32px;
    @include breakpoint($secreen-xs){
        font-size: 20px;
        line-height: 28px;
    }
   
}
.heading-H4{
    font-family: $font-family-Syne-Bold;
    font-size: $Font-size-18px;
    line-height: $line-height-32px;
}
.heading-L{
    font-family: $font-family-Syne-Medium;
    font-size: $Font-size-20px;
    line-height: $line-height-28px;
  
   
}
.heading-M{
    font-family: $font-family-Syne-Medium;
    font-size: $Font-size-18px;
    line-height: $line-height-26px;
   
}
.heading-S{
    font-family: $font-family-Syne-Medium;
    font-size: $Font-size-16px;
    line-height: $line-height-24px;
   
}

.btn-theme{
    padding: 17px 74px;
    background-color: #E6E9EF;
    color: $black;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Syne-Bold';
    box-shadow: inset 5px 5px 15px #D1D9E6, inset -5px -5px 15px #FFFFFF;
    &:hover{
        box-shadow: $Box-Shadow;
        color: $black;
    }
}