@keyframes moveDot1 {
  from {
    transform: rotate(0deg);
    /* transform-origin: top left */
    transform-origin: -20px -20px;
    transform-style: preserve-3D;
  }

  to {
    transform: rotate(-360deg);
    /* transform-origin: top left */
    transform-origin: -20px -20px;
    transform-style: preserve-3D;
  }
}

@keyframes moveDot2 {
  from {
    transform: rotate(0deg);
    /* transform-origin: top right */
    transform-origin: 40px -20px;
    transform-style: preserve-3D;
  }

  to {
    transform: rotate(-360deg);
    /* transform-origin: top right */
    transform-origin: 40px -20px;
    transform-style: preserve-3D;
  }
}

@keyframes moveDot3 {
  from {
    transform: rotate(0deg);
    /* transform-origin: bottom left */
    transform-origin: -20px 40px;
    transform-style: preserve-3D;
  }

  to {
    transform: rotate(-360deg);
    /* transform-origin: bottom left */
    transform-origin: -20px 40px;
    transform-style: preserve-3D;
  }
}

@keyframes moveDot4 {
  from {
    transform: rotate(0deg);
    /* transform-origin: bottom right */
    transform-origin: 40px 40px;
    transform-style: preserve-3D;
  }

  to {
    transform: rotate(-360deg);
    /* transform-origin: bottom right */
    transform-origin: 40px 40px;
    transform-style: preserve-3D;
  }
}

/*=====================================
        Color Variable's
=====================================*/

/*=====================================
        Font Size of Variable's
=====================================*/

/*=====================================
     Variable's Line-Height
=====================================*/

/*=====================================
             Font-family
=====================================*/

.loader-main {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999999;
  background-color: #E5E5E5;
}

.loader-main .loader-container {
  position: relative;
  width: 18rem;
  height: 18rem;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-main .loader-container .dot {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.loader-main .loader-container .dot-1 {
  background-color: #3db5e9;
  z-index: 4;
  animation: moveDot1 2s infinite ease;
}

.loader-main .loader-container .dot-2 {
  background-color: #1de1c0;
  z-index: 3;
  animation: moveDot2 2s infinite ease;
}

.loader-main .loader-container .dot-3 {
  background-color: #005cff;
  z-index: 2;
  animation: moveDot3 2s infinite ease;
}

.loader-main .loader-container .dot-4 {
  background-color: #03007f;
  z-index: 1;
  animation: moveDot4 2s infinite ease;
}

.fixed-icons {
  position: fixed;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999999;
}

.fixed-icons .icon-ul .icon-li:not(:last-child) {
  margin-bottom: 16px;
}

.fixed-icons .icon-ul .icon-li .icon-otr {
  width: 52px;
  height: 52px;
  border-radius: 100%;
  background: #E5E5E5;
  box-shadow: inset 5px 5px 15px #D1D9E6, inset -5px -5px 15px #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
}

.fixed-icons .icon-ul .icon-li .icon-otr:hover {
  background-color: #3fbb4e;
  box-shadow: none;
}

.fixed-icons .icon-ul .icon-li .icon-otr:hover .icon-inr {
  color: #FFFFFF;
}

.fixed-icons .icon-ul .icon-li .icon-otr .icon-inr {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.4);
  transition: 0.3s;
}

/*=========================================
        Navbar Hero Start Here
=========================================*/

.hero-main {
  background-image: url("/assets/img/hero-bg.jpg");
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  background-color: #E5E5E5;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hero-main .container {
    width: 100%;
    max-width: 100%;
  }
}

.hero-main .container .nave {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 36px 0;
}

@media (max-width: 991px) {
  .hero-main .container .nave {
    display: none;
  }
}

.hero-main .container .nave .ul-items {
  display: flex;
  align-items: center;
}

.hero-main .container .nave .ul-items .li-items {
  position: relative;
  cursor: pointer;
}

.hero-main .container .nave .ul-items .li-items:hover .menu-drop-otr {
  opacity: 1;
  visibility: visible;
  top: 12px;
}

.hero-main .container .nave .ul-items .li-items:not(:last-child) {
  margin-right: 40px;
}

.hero-main .container .nave .ul-items .li-items .a-items {
  color: rgba(0, 0, 0, 0.65);
  transition: 0.3s;
}

.hero-main .container .nave .ul-items .li-items .a-items:hover {
  color: #000000;
}

.hero-main .container .nave .ul-items .li-items .menu-drop-otr {
  position: absolute;
  left: -70px;
  top: 100px;
  padding-top: 24px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  z-index: 9999;
}

.hero-main .container .nave .ul-items .li-items .menu-drop-otr .drop-ul {
  width: 250px;
  padding: 16px 16px;
  background-color: white;
  border-radius: 4px;
  z-index: 9999;
}

.hero-main .container .nave .ul-items .li-items .menu-drop-otr .drop-ul .drop-li {
  position: relative;
}

.hero-main .container .nave .ul-items .li-items .menu-drop-otr .drop-ul .drop-li:hover .other-drop-otr {
  opacity: 1;
  visibility: visible;
}

.hero-main .container .nave .ul-items .li-items .menu-drop-otr .drop-ul .drop-li:not(:last-child) {
  margin-bottom: 12px;
}

.hero-main .container .nave .ul-items .li-items .menu-drop-otr .drop-ul .drop-li .drop-a {
  position: relative;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  transition: 0.3s;
}

.hero-main .container .nave .ul-items .li-items .menu-drop-otr .drop-ul .drop-li .drop-a:hover {
  color: #000000;
}

.hero-main .container .nave .ul-items .li-items .menu-drop-otr .drop-ul .drop-li .other-drop-otr {
  position: absolute;
  right: 230px;
  top: -50px;
  margin-top: 34px;
  opacity: 0;
  visibility: hidden;
  border-right: 1px solid rgba(0, 0, 0, 0.4);
  transition: 0.3s;
  z-index: 9999;
}

.hero-main .container .nave .ul-items .li-items .menu-drop-otr .drop-ul .drop-li .other-drop-otr .other-drop-ul {
  width: 300px;
  padding: 16px 24px;
  background-color: white;
  border-radius: 4px;
  z-index: 9999;
}

.hero-main .container .nave .ul-items .li-items .menu-drop-otr .drop-ul .drop-li .other-drop-otr .other-drop-ul .other-drop-li:not(:last-child) {
  margin-bottom: 12px;
}

.hero-main .container .nave .ul-items .li-items .menu-drop-otr .drop-ul .drop-li .other-drop-otr .other-drop-ul .other-drop-li .other-drop-a {
  position: relative;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.65);
  transition: 0.3s;
}

.hero-main .container .nave .ul-items .li-items .menu-drop-otr .drop-ul .drop-li .other-drop-otr .other-drop-ul .other-drop-li .other-drop-a:hover {
  color: #000000;
}

.hero-main .container .nave .logo-otr {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hero-main .container .mobile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 36px 0;
}

@media (min-width: 992px) {
  .hero-main .container .mobile-header {
    display: none;
  }
}

@media (max-width: 575px) {
  .hero-main .container .mobile-header {
    padding: 20px 0;
  }
}

.hero-main .container .mobile-header .mobile-logo {
  width: 100px;
}

.hero-main .container .mobile-header .burger-icon .icon-otr {
  width: 52px;
  height: 52px;
  border-radius: 100%;
  background: #E5E5E5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-main .container .mobile-header .burger-icon .icon-otr:focus {
  box-shadow: none;
}

.hero-main .container .mobile-header .burger-icon .icon-otr .icon-innr {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.4);
}

.hero-main .container .mobile-header .offcanvas .offcanvas-header .logo-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 360px;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-header .logo-icon .img-otr .img-innr {
  width: 100px;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-header .logo-icon .icon-otr {
  width: 52px;
  height: 52px;
  border-radius: 100%;
  background: #E5E5E5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-header .logo-icon .icon-otr .icon-innr {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.4);
}

.hero-main .container .mobile-header .offcanvas .offcanvas-header .logo-icon .icon-2 {
  padding-left: 16px;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-header .logo-icon .icon-2 .icon-innr2 {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.4);
}

.hero-main .container .mobile-header .offcanvas .offcanvas-body {
  padding-top: 48px;
  padding-bottom: 30px;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-body .menu-otr .menu-innr .ul-items .li-items:not(:last-child) {
  margin-bottom: 12px;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-body .menu-otr .menu-innr .ul-items .li-items:hover .a-items {
  color: #000000;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-body .menu-otr .menu-innr .ul-items .li-items .a-items {
  color: rgba(0, 0, 0, 0.65);
  transition: 0.4s;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-body .menu-otr .menu-innr .ul-items .li-items .menu-drop-otr .drop-ul .drop-li .drop-a {
  color: rgba(0, 0, 0, 0.65);
  transition: 0.3s;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-body .menu-otr .menu-innr .ul-items .li-items .menu-drop-otr .drop-ul .drop-li .drop-a:hover {
  color: #000000;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-body .menu-otr .menu-innr .ul-items-small {
  padding-top: 36px;
  padding-bottom: 46px;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-body .menu-otr .menu-innr .ul-items-small .li-items-small:not(:last-child) {
  margin-bottom: 8px;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-body .menu-otr .menu-innr .ul-items-small .li-items-small:hover .a-items-small {
  color: #000000;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-body .menu-otr .menu-innr .ul-items-small .li-items-small .a-items-small {
  color: rgba(0, 0, 0, 0.65);
  transition: 0.4s;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-body .menu-otr .menu-innr .ul-items-small .li-items-small .menu-drop-otr .drop-ul .drop-li .drop-a {
  color: rgba(0, 0, 0, 0.65);
  transition: 0.3s;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-body .menu-otr .menu-innr .ul-items-small .li-items-small .menu-drop-otr .drop-ul .drop-li .drop-a:hover {
  color: #000000;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-body .menu-otr .menu-innr .col-innr {
  box-shadow: inset 9px 9px 15px #D1D9E6, inset -9px -9px 15px #FFFFFF;
  padding: 18px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-body .menu-otr .menu-innr .col-innr:hover {
  box-shadow: 0px 50px 75px rgba(0, 0, 0, 0.25);
}

.hero-main .container .mobile-header .offcanvas .offcanvas-body .menu-otr .menu-innr .col-innr .icon-otr .icon-innr {
  color: rgba(0, 0, 0, 0.4);
  font-size: 24px;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-body .menu-otr .menu-innr .col-innr .icon-otr .icon-innr2 {
  font-size: 32px;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-body .menu-otr .menu-innr .col-innr .contact-info {
  color: #000000;
  font-feature-settings: "pnum" on, "lnum" on;
}

.hero-main .container .hero-inr {
  padding: 72px 0 128px 0;
  position: relative;
  z-index: 10;
}

@media (max-width: 575px) {
  .hero-main .container .hero-inr {
    padding: 40px 0 70px 0;
  }
}

.hero-main .container .hero-inr::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 128px;
  transform: translateX(-50%);
  width: 730px;
  height: 730px;
  border-radius: 100%;
  background-color: #E5E5E5;
  box-shadow: inset 18px 18px 30px #D1D9E6, inset -18px -18px 30px #FFFFFF;
  z-index: -1;
}

@media (max-width: 1400px) {
  .hero-main .container .hero-inr::after {
    width: 650px;
    height: 650px;
  }
}

@media (max-width: 991px) {
  .hero-main .container .hero-inr::after {
    width: 400px;
    height: 400px;
  }
}

@media (max-width: 575px) {
  .hero-main .container .hero-inr::after {
    display: none;
  }
}

.hero-main .container .hero-inr .heading {
  text-align: center;
  padding: 0 0 60px 0;
}

@media (max-width: 575px) {
  .hero-main .container .hero-inr .heading {
    padding: 0 0 30px 0;
  }
}

.hero-main .container .hero-inr .hero-img {
  width: 100%;
}

.hero-main .container .hero-inr .explore-btn {
  text-align: center;
  position: absolute;
  width: 150px;
  bottom: 64px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
}

@media (max-width: 575px) {
  .hero-main .container .hero-inr .explore-btn {
    display: none;
  }
}

.hero-main .container .hero-inr .explore-btn .arrow-img {
  position: relative;
  top: -20px;
}

.hero-main .marquee {
  position: absolute;
  top: 32%;
}

.hero-main .marquee .marquee-ul {
  display: flex;
  align-items: center;
  position: relative;
  left: -1700px;
}

.hero-main .marquee .marquee-ul .marquee-li {
  color: #000000;
  opacity: 0.07;
  font-size: 96px;
  font-family: "Syne-ExtraBold";
}

.hero-main .marquee .marquee-ul .marquee-li:not(:last-child) {
  padding-left: 50px;
}

/*=========================================
        Navbar Hero End Here
=========================================*/

/*=========================================
        Catalouge Start Here
=========================================*/

.catalouge {
  padding-top: 128px;
  padding-bottom: 128px;
}

@media (max-width: 575px) {
  .catalouge {
    padding: 80px 0;
  }
}

.catalouge .container .heading-main {
  color: black;
  text-align: center;
  margin-bottom: 72px;
}

@media (max-width: 991px) {
  .catalouge .container .heading-main {
    margin-bottom: 48px;
  }
}

.catalouge .container .row-custom .swiper-otr .mySwiper .swiper-wrapper {
  height: auto;
}

.catalouge .container .row-custom .swiper-otr .mySwiper .swiper-wrapper .swiper-slide .img-fluid {
  width: 100%;
}

.catalouge .container .row-custom .swiper-otr .mySwiper .swiper-pagination {
  padding: 8px;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(15px);
  color: white;
  bottom: 24px;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
}

.catalouge .container .row-custom .swiper-otr .mySwiper .swiper-pagination .swiper-pagination-bullet {
  background-color: #fff;
  opacity: 1;
}

.catalouge .container .row-custom .swiper-otr .mySwiper .swiper-pagination .swiper-pagination-bullet-active {
  background-color: black;
}

.catalouge .container .row-custom .swiper-otr .heading-box {
  padding: 0;
  background-color: transparent;
  border: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding-top: 28px;
  padding-bottom: 37px;
}

.catalouge .container .row-custom .swiper-otr .modal-otr .modal-dialog-custom {
  max-width: 1140px;
  position: relative;
}

.catalouge .container .row-custom .swiper-otr .modal-otr .modal-dialog-custom .modal-content-custom .icon-otr {
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 9999;
}

.catalouge .container .row-custom .swiper-otr .modal-otr .modal-dialog-custom .modal-content-custom .icon-otr:hover .icon {
  color: #000000;
}

@media (max-width: 575px) {
  .catalouge .container .row-custom .swiper-otr .modal-otr .modal-dialog-custom .modal-content-custom .icon-otr {
    top: 2px;
    right: 4px;
  }
}

.catalouge .container .row-custom .swiper-otr .modal-otr .modal-dialog-custom .modal-content-custom .icon-otr .icon {
  font-size: 36px;
  color: rgba(0, 0, 0, 0.4);
  transition: 0.4s;
  cursor: pointer;
}

@media (max-width: 575px) {
  .catalouge .container .row-custom .swiper-otr .modal-otr .modal-dialog-custom .modal-content-custom .icon-otr .icon {
    font-size: 25px;
  }
}

.catalouge .container .row-custom .swiper-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom {
  padding: 0px;
}

.catalouge .container .row-custom .swiper-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom {
  padding: 72px;
}

@media (max-width: 575px) {
  .catalouge .container .row-custom .swiper-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom {
    padding: 40px 15px;
  }
}

@media (max-width: 575px) {
  .catalouge .container .row-custom .swiper-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom {
    flex-direction: column-reverse;
  }
}

@media (max-width: 575px) {
  .catalouge .container .row-custom .swiper-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .swiper-otr {
    padding-top: 30px;
  }
}

.catalouge .container .row-custom .swiper-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .swiper-otr .mySwiper .swiper-wrapper {
  height: auto;
}

.catalouge .container .row-custom .swiper-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .swiper-otr .mySwiper .swiper-wrapper .swiper-slide .img-fluid {
  width: 100%;
}

.catalouge .container .row-custom .swiper-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .swiper-otr .mySwiper .swiper-pagination {
  padding: 8px;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(15px);
  color: white;
  bottom: 24px;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
}

.catalouge .container .row-custom .swiper-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .swiper-otr .mySwiper .swiper-pagination .swiper-pagination-bullet {
  background-color: #fff;
  opacity: 1;
}

.catalouge .container .row-custom .swiper-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .swiper-otr .mySwiper .swiper-pagination .swiper-pagination-bullet-active {
  background-color: black;
}

.catalouge .container .row-custom .swiper-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .content {
  padding-left: 69px;
}

@media (max-width: 991px) {
  .catalouge .container .row-custom .swiper-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .content {
    padding-left: 10px;
  }
}

.catalouge .container .row-custom .swiper-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .content .content-innr {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

@media (max-width: 991px) {
  .catalouge .container .row-custom .swiper-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .content .content-innr .content-div {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.catalouge .container .row-custom .swiper-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .content .content-innr .content-div .heading-H2 {
  color: #000000;
  text-align: start;
}

@media (max-width: 991px) {
  .catalouge .container .row-custom .swiper-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .content .content-innr .content-div .heading-H2 {
    margin-bottom: 0px;
    font-size: 35px;
  }
}

.catalouge .container .row-custom .swiper-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .content .content-innr .content-div .ul-content {
  padding-top: 28px;
}

@media (max-width: 991px) {
  .catalouge .container .row-custom .swiper-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .content .content-innr .content-div .ul-content {
    padding-top: 15px;
  }
}

@media (max-width: 575px) {
  .catalouge .container .row-custom .swiper-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .content .content-innr .content-div .ul-content {
    margin-bottom: 20px;
  }
}

.catalouge .container .row-custom .swiper-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .content .content-innr .content-div .ul-content .content1 {
  display: flex;
}

.catalouge .container .row-custom .swiper-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .content .content-innr .content-div .ul-content .content1:not(:last-child) {
  padding-bottom: 12px;
}

.catalouge .container .row-custom .swiper-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .content .content-innr .content-div .ul-content .content1 .txt1 {
  color: #000000;
}

.catalouge .container .row-custom .swiper-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .content .content-innr .content-div .ul-content .content1 .txt2 {
  color: rgba(0, 0, 0, 0.4);
  padding-left: 5px;
}

.catalouge .container .row-custom .swiper-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .content .content-innr .action-otr {
  display: flex;
}

.catalouge .container .row-custom .swiper-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .content .content-innr .action-otr .action-innr {
  padding: 18px 40px;
  background-color: #E5E5E5;
  color: black;
  font-size: 16px;
  line-height: 24px;
  font-family: "Syne-Bold";
  box-shadow: inset 5px 5px 15px #D1D9E6, inset -5px -5px 15px #FFFFFF;
}

@media (max-width: 991px) {
  .catalouge .container .row-custom .on-mobile {
    display: none;
  }
}

.catalouge .container .action {
  padding-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*=========================================
        Catalouge end Here
=========================================*/

/*=========================================
        Satisfaction Start Here
=========================================*/

.satisfaction {
  overflow: hidden;
  background-color: #E5E5E5;
  padding-top: 128px;
  padding-bottom: 128px;
}

@media (max-width: 575px) {
  .satisfaction {
    padding: 80px 0;
  }
}

.satisfaction .container .heading-H2 {
  color: #000000;
  text-align: center;
  padding-bottom: 72px;
  font-feature-settings: "pnum" on, "lnum" on;
}

@media (max-width: 991px) {
  .satisfaction .container .heading-H2 {
    padding-bottom: 48px;
  }
}

.satisfaction .container .mySwiper {
  overflow: visible;
}

.satisfaction .container .mySwiper .swiper-wrapper {
  height: auto;
}

.satisfaction .container .mySwiper .swiper-wrapper .swiper-slide .main {
  padding: 72px;
  background-color: #E6E9EF;
  box-shadow: inset 5px 5px 15px #D1D9E6, inset -5px -5px 15px #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: visible !important;
}

@media (max-width: 991px) {
  .satisfaction .container .mySwiper .swiper-wrapper .swiper-slide .main {
    flex-direction: column;
    padding: 40px;
  }
}

@media (max-width: 575px) {
  .satisfaction .container .mySwiper .swiper-wrapper .swiper-slide .main {
    padding: 40px 20px;
  }
}

.satisfaction .container .mySwiper .swiper-wrapper .swiper-slide .main .img-otr {
  padding-right: 109px;
}

@media (max-width: 991px) {
  .satisfaction .container .mySwiper .swiper-wrapper .swiper-slide .main .img-otr {
    padding-right: 0px;
    padding-bottom: 34px;
  }
}

.satisfaction .container .mySwiper .swiper-wrapper .swiper-slide .main .img-otr .img-innr {
  width: 170px;
  height: 170px;
  object-fit: cover;
}

@media (max-width: 991px) {
  .satisfaction .container .mySwiper .swiper-wrapper .swiper-slide .main .content {
    text-align: center;
  }
}

.satisfaction .container .mySwiper .swiper-wrapper .swiper-slide .main .content .heading-H3 {
  padding-bottom: 24px;
  color: black;
}

@media (max-width: 991px) {
  .satisfaction .container .mySwiper .swiper-wrapper .swiper-slide .main .content .heading-H3 {
    padding-bottom: 24px;
  }
}

.satisfaction .container .mySwiper .swiper-wrapper .swiper-slide .main .content .txt1 {
  color: rgba(0, 0, 0, 0.65);
  padding-bottom: 4px;
}

.satisfaction .container .mySwiper .swiper-wrapper .swiper-slide .main .content .txt2 {
  color: rgba(0, 0, 0, 0.4);
}

.satisfaction .container .mySwiper .swiper-pagination {
  bottom: -53px;
}

@media (max-width: 575px) {
  .satisfaction .container .mySwiper .swiper-pagination {
    bottom: -20px;
  }
}

.satisfaction .container .mySwiper .swiper-pagination .swiper-pagination-bullet {
  background-color: #fff;
  opacity: 1;
}

.satisfaction .container .mySwiper .swiper-pagination .swiper-pagination-bullet-active {
  background-color: black;
}

.satisfaction .container .mySwiper .swiper-button-prev {
  width: 52px;
  height: 52px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: -95px;
  background: #E5E5E5 url("/assets/img/arrow-left-line.svg") no-repeat;
  background-position: center;
  box-shadow: inset 5px 5px 15px #D1D9E6, inset -5px -5px 15px #FFFFFF;
  transition: 0.4s;
}

@media (max-width: 1400px) {
  .satisfaction .container .mySwiper .swiper-button-prev {
    left: -26px;
  }
}

.satisfaction .container .mySwiper .swiper-button-prev::after {
  display: none;
}

.satisfaction .container .mySwiper .swiper-button-prev:hover {
  background: #FFFFFF url("/assets/img/hover-pre.svg") no-repeat;
  background-position: center;
}

@media (max-width: 991px) {
  .satisfaction .container .mySwiper .swiper-button-prev {
    display: none;
  }
}

.satisfaction .container .mySwiper .swiper-button-next {
  width: 52px;
  height: 52px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -95px;
  background: #E5E5E5 url("/assets/img/arrow-right-line.svg") no-repeat;
  background-position: center;
  box-shadow: inset 5px 5px 15px #D1D9E6, inset -5px -5px 15px #FFFFFF;
  transition: 0.4s;
}

@media (max-width: 1400px) {
  .satisfaction .container .mySwiper .swiper-button-next {
    right: -26px;
  }
}

@media (max-width: 991px) {
  .satisfaction .container .mySwiper .swiper-button-next {
    display: none;
  }
}

.satisfaction .container .mySwiper .swiper-button-next::after {
  display: none;
}

.satisfaction .container .mySwiper .swiper-button-next:hover {
  background: #FFFFFF url("/assets/img/right-hover.svg") no-repeat;
  background-position: center;
}

.bg-light .swiper-pagination-bullet {
  background-color: #E5E5E5 !important;
}

.bg-light .swiper-pagination-bullet-active {
  background-color: #000000 !important;
}

/*=========================================
        Satisfaction Start Here
=========================================*/

/*------------------------------------------
 Popular Section Start Here
-------------------------------------------*/

.popular-section {
  padding-top: 128px;
  padding-bottom: 128px;
}

@media (max-width: 575px) {
  .popular-section {
    padding: 80px 0 40px 0;
  }
}

.popular-section .container .heading-H2 {
  color: #000000;
  text-align: center;
  padding-bottom: 72px;
}

@media (max-width: 991px) {
  .popular-section .container .heading-H2 {
    padding-bottom: 48px;
  }
}

.popular-section .container .row-custom .slider-otr .heading-box {
  padding: 0;
  background-color: transparent;
  border: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding-top: 28px;
  padding-bottom: 37px;
}

.popular-section .container .row-custom .slider-otr .modal-otr .modal-dialog-custom {
  max-width: 1140px;
  position: relative;
}

.popular-section .container .row-custom .slider-otr .modal-otr .modal-dialog-custom .modal-content-custom .icon-otr {
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 9999;
}

.popular-section .container .row-custom .slider-otr .modal-otr .modal-dialog-custom .modal-content-custom .icon-otr:hover .icon {
  color: #000000;
}

@media (max-width: 575px) {
  .popular-section .container .row-custom .slider-otr .modal-otr .modal-dialog-custom .modal-content-custom .icon-otr {
    top: 2px;
    right: 4px;
  }
}

.popular-section .container .row-custom .slider-otr .modal-otr .modal-dialog-custom .modal-content-custom .icon-otr .icon {
  font-size: 36px;
  color: rgba(0, 0, 0, 0.4);
  transition: 0.4s;
  cursor: pointer;
}

@media (max-width: 575px) {
  .popular-section .container .row-custom .slider-otr .modal-otr .modal-dialog-custom .modal-content-custom .icon-otr .icon {
    font-size: 25px;
  }
}

.popular-section .container .row-custom .slider-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom {
  padding: 0px;
}

.popular-section .container .row-custom .slider-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom {
  padding: 72px;
}

@media (max-width: 575px) {
  .popular-section .container .row-custom .slider-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom {
    padding: 40px 15px;
  }
}

@media (max-width: 575px) {
  .popular-section .container .row-custom .slider-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom {
    flex-direction: column-reverse;
  }
}

@media (max-width: 575px) {
  .popular-section .container .row-custom .slider-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .swiper-otr {
    padding-top: 30px;
  }
}

.popular-section .container .row-custom .slider-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .swiper-otr .mySwiper .swiper-wrapper {
  height: auto;
}

.popular-section .container .row-custom .slider-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .swiper-otr .mySwiper .swiper-wrapper .swiper-slide .img-fluid {
  width: 100%;
}

.popular-section .container .row-custom .slider-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .swiper-otr .mySwiper .swiper-pagination {
  padding: 8px;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(15px);
  color: white;
  bottom: 24px;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
}

.popular-section .container .row-custom .slider-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .swiper-otr .mySwiper .swiper-pagination .swiper-pagination-bullet {
  background-color: #fff;
  opacity: 1;
}

.popular-section .container .row-custom .slider-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .swiper-otr .mySwiper .swiper-pagination .swiper-pagination-bullet-active {
  background-color: black;
}

.popular-section .container .row-custom .slider-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .content {
  padding-left: 69px;
}

@media (max-width: 991px) {
  .popular-section .container .row-custom .slider-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .content {
    padding-left: 10px;
  }
}

.popular-section .container .row-custom .slider-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .content .content-innr {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

@media (max-width: 991px) {
  .popular-section .container .row-custom .slider-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .content .content-innr .content-div {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.popular-section .container .row-custom .slider-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .content .content-innr .content-div .heading-H2 {
  color: #000000;
  text-align: start;
  padding-bottom: 0;
}

@media (max-width: 991px) {
  .popular-section .container .row-custom .slider-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .content .content-innr .content-div .heading-H2 {
    margin-bottom: 0px;
    font-size: 35px;
  }
}

.popular-section .container .row-custom .slider-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .content .content-innr .content-div .ul-content {
  padding-top: 28px;
}

@media (max-width: 991px) {
  .popular-section .container .row-custom .slider-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .content .content-innr .content-div .ul-content {
    padding-top: 15px;
  }
}

@media (max-width: 575px) {
  .popular-section .container .row-custom .slider-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .content .content-innr .content-div .ul-content {
    margin-bottom: 20px;
  }
}

.popular-section .container .row-custom .slider-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .content .content-innr .content-div .ul-content .content1 {
  display: flex;
}

.popular-section .container .row-custom .slider-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .content .content-innr .content-div .ul-content .content1:not(:last-child) {
  padding-bottom: 12px;
}

.popular-section .container .row-custom .slider-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .content .content-innr .content-div .ul-content .content1 .txt1 {
  color: #000000;
}

.popular-section .container .row-custom .slider-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .content .content-innr .content-div .ul-content .content1 .txt2 {
  color: rgba(0, 0, 0, 0.4);
  padding-left: 5px;
}

.popular-section .container .row-custom .slider-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .content .content-innr .action-otr {
  display: flex;
}

.popular-section .container .row-custom .slider-otr .modal-otr .modal-dialog-custom .modal-content-custom .modal-body-custom .pop-up .row-custom .content .content-innr .action-otr .action-innr {
  padding: 18px 40px;
  background-color: #E5E5E5;
  color: black;
  font-size: 16px;
  line-height: 24px;
  font-family: "Syne-Bold";
  box-shadow: inset 5px 5px 15px #D1D9E6, inset -5px -5px 15px #FFFFFF;
}

.popular-section .container .row-custom .slider-otr .swiper {
  height: auto !important;
  margin-bottom: 24px;
}

.popular-section .container .row-custom .slider-otr .swiper .swiper-wrapper .swiper-slide .img {
  width: 100%;
  height: 267px;
  object-fit: cover;
}

.popular-section .container .row-custom .slider-otr .swiper {
  height: auto !important;
}

.popular-section .container .row-custom .slider-otr .swiper .swiper-wrapper {
  height: auto !important;
}

.popular-section .container .row-custom .slider-otr .swiper .swiper-wrapper .swiper-slide .img2 {
  width: 100%;
  height: 73px;
  object-fit: cover;
}

.popular-section .container .row-custom .slider-otr .heading-H3 {
  color: #000000;
  text-align: center;
  padding: 0 0 40px 0;
  font-feature-settings: "pnum" on, "lnum" on;
}

@media (max-width: 991px) {
  .popular-section .container .row-custom .for-mobile {
    display: none;
  }
}

.popular-section .container .action-otr {
  display: flex;
  margin: 32px 0 0 0;
}

.popular-section .container .action-otr .btn-theme {
  padding-top: 72px;
}

.popular-section .container .action-otr1 {
  display: flex;
  justify-content: center;
  padding-top: 72px;
}

/*------------------------------------------
 Popular Section End Here
-------------------------------------------*/

/*------------------------------------------
 Footer  Start Here
-------------------------------------------*/

.footer {
  background-color: #E5E5E5;
}

.footer .main {
  padding: 120px 0 136px 0;
}

@media (max-width: 575px) {
  .footer .main {
    padding: 80px 0;
  }
}

.footer .main .container .ul-items {
  padding-bottom: 48px;
  display: flex;
  justify-content: space-between;
  gap: 50px;
}

@media (max-width: 991px) {
  .footer .main .container .ul-items {
    flex-wrap: wrap;
    justify-content: initial;
    gap: 30px;
    padding-bottom: 20px;
  }
}

.footer .main .container .ul-items .li-items:hover .a-items {
  color: #000000;
}

.footer .main .container .ul-items .li-items .a-items {
  color: rgba(0, 0, 0, 0.4);
  transition: 0.4s;
}

@media (max-width: 991px) {
  .footer .main .container .row-custom {
    flex-direction: column;
  }
}

.footer .main .container .row-custom .col-otr {
  padding-top: 24px;
}

.footer .main .container .row-custom .col-otr .col-innr {
  box-shadow: inset 9px 9px 15px #D1D9E6, inset -9px -9px 15px #FFFFFF;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
}

@media (max-width: 991px) {
  .footer .main .container .row-custom .col-otr .col-innr {
    flex-direction: column;
  }
}

@media (max-width: 575px) {
  .footer .main .container .row-custom .col-otr .col-innr {
    padding: 24px;
  }
}

.footer .main .container .row-custom .col-otr .col-innr:hover {
  box-shadow: 0px 50px 75px rgba(0, 0, 0, 0.25);
}

.footer .main .container .row-custom .col-otr .col-innr .icon-otr {
  padding-right: 18px;
}

.footer .main .container .row-custom .col-otr .col-innr .icon-otr .icon-innr {
  color: rgba(0, 0, 0, 0.4);
  font-size: 32px;
}

.footer .main .container .row-custom .col-otr .col-innr .icon-otr .icon-innr2 {
  font-size: 32px;
  color: #3fbb4e;
}

.footer .main .container .row-custom .col-otr .col-innr .contact-info {
  color: #000000;
  font-feature-settings: "pnum" on, "lnum" on;
  text-align: center;
  line-break: anywhere;
}

.footer .copy-main {
  background-color: #FFFFFF;
}

.footer .copy-main .container .try {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #FFFFFF;
  padding: 33px 0;
  width: 100%;
  position: relative;
}

@media (max-width: 991px) {
  .footer .copy-main .container .try {
    flex-direction: column;
  }
}

@media (max-width: 991px) {
  .footer .copy-main .container .try .logo-footer .logo-inr {
    width: 100px;
  }
}

.footer .copy-main .container .try .copy-right {
  color: rgba(0, 0, 0, 0.65);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 991px) {
  .footer .copy-main .container .try .copy-right {
    text-align: center;
    position: relative;
    left: 0;
    top: 0;
    transform: translate(0);
    padding: 20px 0;
  }
}

.footer .copy-main .container .try .copy-right .site-name {
  display: inline-block;
  color: rgba(0, 0, 0, 0.65);
  transition: 0.3s;
}

.footer .copy-main .container .try .copy-right .site-name:hover {
  color: #000000;
}

.footer .copy-main .container .try .dot {
  color: rgba(0, 0, 0, 0.4);
}

.footer .copy-main .container .try .links {
  color: rgba(0, 0, 0, 0.4);
  transition: 0.3s;
  position: relative;
}

.footer .copy-main .container .try .links:hover {
  color: #000000;
}

.footer .copy-main .container .try .links:hover::after {
  background-color: #000000;
}

.footer .copy-main .container .try .links::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 0.3s;
}

/*------------------------------------------
 Footer  End Here
-------------------------------------------*/

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
  padding: 0px;
}

@media (max-width: 767px) {
  br {
    display: none;
  }
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  display: block;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.heading-H1 {
  font-family: "Syne-Bold";
  font-size: 72px;
}

@media (max-width: 991px) {
  .heading-H1 {
    font-size: 48px;
  }
}

@media (max-width: 575px) {
  .heading-H1 {
    font-size: 36px;
    line-height: 52px;
  }
}

.heading-H2 {
  font-family: "Syne-Bold";
  font-size: 48px;
}

@media (max-width: 575px) {
  .heading-H2 {
    font-size: 32px;
    line-height: 44px;
  }
}

.heading-H3 {
  font-family: "Syne-Bold";
  font-size: 24px;
  line-height: 32px;
}

@media (max-width: 575px) {
  .heading-H3 {
    font-size: 20px;
    line-height: 28px;
  }
}

.heading-H4 {
  font-family: "Syne-Bold";
  font-size: 18px;
  line-height: 32px;
}

.heading-L {
  font-family: "Syne-Medium";
  font-size: 20px;
  line-height: 28px;
}

.heading-M {
  font-family: "Syne-Medium";
  font-size: 18px;
  line-height: 26px;
}

.heading-S {
  font-family: "Syne-Medium";
  font-size: 16px;
  line-height: 24px;
}

.btn-theme {
  padding: 17px 74px;
  background-color: #E6E9EF;
  color: #000000;
  font-size: 16px;
  line-height: 24px;
  font-family: "Syne-Bold";
  box-shadow: inset 5px 5px 15px #D1D9E6, inset -5px -5px 15px #FFFFFF;
}

.btn-theme:hover {
  box-shadow: 0px 50px 75px rgba(0, 0, 0, 0.25);
  color: #000000;
}

/*=========================================
        Navbar Hero Start Here
=========================================*/

.hero-main {
  background-image: url("/assets/img/hero-bg.jpg");
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  background-color: #E5E5E5;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hero-main .container {
    width: 100%;
    max-width: 100%;
  }
}

.hero-main .container .nave {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 36px 0;
}

@media (max-width: 991px) {
  .hero-main .container .nave {
    display: none;
  }
}

.hero-main .container .nave .ul-items {
  display: flex;
  align-items: center;
}

.hero-main .container .nave .ul-items .li-items:not(:last-child) {
  margin-right: 40px;
}

.hero-main .container .nave .ul-items .li-items .a-items {
  color: rgba(0, 0, 0, 0.65);
  transition: 0.3s;
}

.hero-main .container .nave .ul-items .li-items .a-items:hover {
  color: #000000;
}

.hero-main .container .nave .logo-otr {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hero-main .container .mobile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 36px 0;
}

@media (min-width: 992px) {
  .hero-main .container .mobile-header {
    display: none;
  }
}

@media (max-width: 575px) {
  .hero-main .container .mobile-header {
    padding: 20px 0;
  }
}

.hero-main .container .mobile-header .mobile-logo {
  width: 100px;
}

.hero-main .container .mobile-header .burger-icon .icon-otr {
  width: 52px;
  height: 52px;
  border-radius: 100%;
  background: #E5E5E5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-main .container .mobile-header .burger-icon .icon-otr:focus {
  box-shadow: none;
}

.hero-main .container .mobile-header .burger-icon .icon-otr .icon-innr {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.4);
}

.hero-main .container .mobile-header .offcanvas .offcanvas-header .logo-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 360px;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-header .logo-icon .img-otr .img-innr {
  width: 100px;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-header .logo-icon .icon-otr {
  width: 52px;
  height: 52px;
  border-radius: 100%;
  background: #E5E5E5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-header .logo-icon .icon-otr .icon-innr {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.4);
}

.hero-main .container .mobile-header .offcanvas .offcanvas-header .logo-icon .icon-2 {
  padding-left: 16px;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-header .logo-icon .icon-2 .icon-innr2 {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.4);
}

.hero-main .container .mobile-header .offcanvas .offcanvas-body {
  padding-top: 48px;
  padding-bottom: 30px;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-body .menu-otr .menu-innr .ul-items .li-items:not(:last-child) {
  margin-bottom: 12px;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-body .menu-otr .menu-innr .ul-items .li-items:hover .a-items {
  color: #000000;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-body .menu-otr .menu-innr .ul-items .li-items .a-items {
  color: rgba(0, 0, 0, 0.65);
  transition: 0.4s;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-body .menu-otr .menu-innr .ul-items-small {
  padding-top: 36px;
  padding-bottom: 46px;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-body .menu-otr .menu-innr .ul-items-small .li-items-small:not(:last-child) {
  margin-bottom: 8px;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-body .menu-otr .menu-innr .ul-items-small .li-items-small:hover .a-items-small {
  color: #000000;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-body .menu-otr .menu-innr .ul-items-small .li-items-small .a-items-small {
  color: rgba(0, 0, 0, 0.65);
  transition: 0.4s;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-body .menu-otr .menu-innr .col-innr {
  box-shadow: inset 9px 9px 15px #D1D9E6, inset -9px -9px 15px #FFFFFF;
  padding: 18px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-body .menu-otr .menu-innr .col-innr:hover {
  box-shadow: 0px 50px 75px rgba(0, 0, 0, 0.25);
}

.hero-main .container .mobile-header .offcanvas .offcanvas-body .menu-otr .menu-innr .col-innr .icon-otr .icon-innr {
  color: rgba(0, 0, 0, 0.4);
  font-size: 24px;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-body .menu-otr .menu-innr .col-innr .icon-otr .icon-innr2 {
  font-size: 32px;
}

.hero-main .container .mobile-header .offcanvas .offcanvas-body .menu-otr .menu-innr .col-innr .contact-info {
  color: #000000;
  font-feature-settings: "pnum" on, "lnum" on;
}

.hero-main .container .heading {
  text-align: center;
  padding: 36px 0 72px 0;
}

/*=========================================
        Navbar Hero End Here
=========================================*/

/*------------------------------------------
        Hero Start Here
-------------------------------------------*/

.hero1 {
  background-color: #E5E5E5;
  padding-top: 36px;
  padding-bottom: 104px;
}

.hero1 .container .nave {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.hero1 .container .nave .ul-items {
  display: flex;
  align-items: center;
}

.hero1 .container .nave .ul-items .li-items:not(:last-child) {
  margin-right: 40px;
}

.hero1 .container .nave .ul-items .li-items .a-items {
  color: rgba(0, 0, 0, 0.65);
}

.hero1 .container .nave .logo-otr {
  position: absolute;
  top: 1px;
  left: 583px;
}

.hero1 .container .heading-H2 {
  text-align: center;
  color: #000000;
  padding-top: 90px;
}

/*------------------------------------------
    Hero End Here
-------------------------------------------*/

/*------------------------------------------
    Section 2 start Here
-------------------------------------------*/

.about-main .container .row-custom {
  padding-top: 145px;
  align-items: center;
}

@media (max-width: 991px) {
  .about-main .container .row-custom {
    flex-direction: column;
  }
}

@media (max-width: 575px) {
  .about-main .container .row-custom {
    padding-top: 80px;
  }
}

@media (max-width: 991px) {
  .about-main .container .row-custom .content-otr {
    padding-bottom: 30px;
  }
}

.about-main .container .row-custom .content-otr .content-innr .heading-H2 {
  color: #000000;
}

.about-main .container .row-custom .content-otr .content-innr .heading-M {
  color: rgba(0, 0, 0, 0.65);
  padding-top: 28px;
}

.about-main .container .row-custom .mySwiper .swiper-wrapper .swiper-slide .img-otr .img-innr {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 24px;
}

.about-main-2 .container .row-custom2 {
  margin-top: 145px;
  margin-bottom: 128px;
}

@media (max-width: 991px) {
  .about-main-2 .container .row-custom2 {
    margin-bottom: 40px;
    margin-top: 40px;
  }
}

@media (max-width: 575px) {
  .about-main-2 .container .row-custom2 .content {
    padding-bottom: 30px;
  }
}

.about-main-2 .container .row-custom2 .content .heading-H2 {
  color: #000000;
  padding-bottom: 28px;
}

.about-main-2 .container .row-custom2 .content .heading-M {
  color: rgba(0, 0, 0, 0.65);
}

/*------------------------------------------
    Section 2 End Here
-------------------------------------------*/

/*------------------------------------------
    Section 3 Start Here
-------------------------------------------*/

.Team-Section {
  padding-top: 128px;
  padding-bottom: 128px;
  background-image: url("/assets/img/hero-bg.jpg");
  background-color: #E5E5E5;
  background-size: cover;
}

@media (max-width: 575px) {
  .Team-Section {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.Team-Section .container .heading-H2 {
  color: #000000;
  text-align: center;
  padding-bottom: 72px;
}

@media (max-width: 575px) {
  .Team-Section .container .heading-H2 {
    padding-bottom: 30px;
  }
}

.Team-Section .container .row-custom .col-box {
  text-align: center;
}

@media (max-width: 991px) {
  .Team-Section .container .row-custom .col-box {
    padding-bottom: 30px;
  }
}

.Team-Section .container .row-custom .col-box .img {
  width: 100%;
}

.Team-Section .container .row-custom .col-box .heading-H3 {
  color: #000000;
  padding-top: 24px;
  padding-bottom: 8px;
}

.Team-Section .container .row-custom .col-box .heading-S {
  color: rgba(0, 0, 0, 0.4);
}

/*------------------------------------------
    Section 3 End Here
-------------------------------------------*/

/*------------------------------------------
          pop-up start Here
-------------------------------------------*/

.pop-up .row-custom {
  padding: 72px;
}

.pop-up .row-custom .swiper-otr .mySwiper .swiper-wrapper {
  height: auto;
}

.pop-up .row-custom .swiper-otr .mySwiper .swiper-wrapper .swiper-slide .img-fluid {
  width: 100%;
}

.pop-up .row-custom .swiper-otr .mySwiper .swiper-pagination {
  padding: 8px;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(15px);
  color: white;
  bottom: 24px;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
}

.pop-up .row-custom .swiper-otr .mySwiper .swiper-pagination .swiper-pagination-bullet {
  background-color: #fff;
  opacity: 1;
}

.pop-up .row-custom .swiper-otr .mySwiper .swiper-pagination .swiper-pagination-bullet-active {
  background-color: black;
}

.pop-up .row-custom .content {
  padding-left: 69px;
}

.pop-up .row-custom .content .content-innr {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.pop-up .row-custom .content .content-innr .content-div .heading-H2 {
  color: #000000;
}

.pop-up .row-custom .content .content-innr .content-div .ul-content {
  padding-top: 28px;
}

.pop-up .row-custom .content .content-innr .content-div .ul-content .content1 {
  display: flex;
}

.pop-up .row-custom .content .content-innr .content-div .ul-content .content1:not(:last-child) {
  padding-bottom: 12px;
}

.pop-up .row-custom .content .content-innr .content-div .ul-content .content1 .txt1 {
  color: #000000;
}

.pop-up .row-custom .content .content-innr .content-div .ul-content .content1 .txt2 {
  color: rgba(0, 0, 0, 0.4);
  padding-left: 5px;
}

.pop-up .row-custom .content .content-innr .action-otr {
  display: flex;
}

.pop-up .row-custom .content .content-innr .action-otr .action-innr {
  padding: 18px 40px;
  background-color: #E5E5E5;
  color: black;
  font-size: 16px;
  line-height: 24px;
  font-family: "Syne-Bold";
  box-shadow: inset 5px 5px 15px #D1D9E6, inset -5px -5px 15px #FFFFFF;
}

/*------------------------------------------
          pop-up End Here
-------------------------------------------*/

.process {
  padding: 144px 0 144px 0;
}

@media (max-width: 991px) {
  .process {
    padding: 72px 0 72px 0;
  }
}

.process .container .row-process1 {
  padding-bottom: 24px;
}

@media (max-width: 991px) {
  .process .container .row-process1 {
    flex-direction: column-reverse;
  }
}

@media (max-width: 991px) {
  .process .container .row-process1 .col-img-otr {
    padding-top: 35px;
  }
}

.process .container .row-process1 .col-img-otr .img {
  width: 100%;
  height: 412px;
  object-fit: cover;
}

@media (max-width: 575px) {
  .process .container .row-process1 .col-img-otr .img {
    height: 200px;
  }
}

@media (max-width: 991px) {
  .process .container .row-process1 .col-content-otr .col-content-inr .heading {
    padding-top: 35px;
  }
}

.process .container .row-process1 .col-content-otr .col-content-inr .desc {
  padding-top: 8px;
}

.process .container .row-process1 .col-content-otr .col-content-inr .desc2 {
  padding-top: 12px;
}

.process .container .row-process2 {
  padding-bottom: 24px;
}

@media (max-width: 991px) {
  .process .container .row-process2 .col-img-otr {
    padding-top: 35px;
  }
}

.process .container .row-process2 .col-img-otr .img {
  width: 100%;
  height: 412px;
  object-fit: cover;
}

@media (max-width: 575px) {
  .process .container .row-process2 .col-img-otr .img {
    height: 200px;
  }
}

.process .container .row-process2 .col-content-otr .col-content-inr .desc {
  padding-top: 8px;
}

.process .container .row-process3 {
  padding-bottom: 24px;
  align-items: center;
}

.process .container .row-process3 .col-img-otr .img {
  width: 100%;
  height: 412px;
  object-fit: cover;
}

@media (max-width: 575px) {
  .process .container .row-process3 .col-img-otr .img {
    height: 200px;
  }
}

@media (max-width: 991px) {
  .process .container .row-process3 .col-content-otr .col-content-inr .heading {
    padding-top: 35px;
  }
}

.process .container .row-process3 .col-content-otr .col-content-inr .desc {
  padding-top: 8px;
}

.process .container .row-process3 .col-content-otr .col-content-inr .desc2 {
  padding-top: 12px;
}

/*------------------------------------------
        Hero Start Here
-------------------------------------------*/

.granite-section .container .row-granite {
  padding: 144px 0 144px 0;
  gap: 40px 0;
}

@media (max-width: 991px) {
  .granite-section .container .row-granite {
    padding: 72px 0 72px 0;
  }
}

.granite-section .container .row-granite .col-item-otr .col-item-inr:hover .name-otr .name {
  color: #000000;
}

.granite-section .container .row-granite .col-item-otr .col-item-inr .img-otr {
  padding-bottom: 16px;
}

.granite-section .container .row-granite .col-item-otr .col-item-inr .img-otr .img {
  width: 100%;
  height: 279px;
  object-fit: cover;
}

.granite-section .container .row-granite .col-item-otr .col-item-inr .name-otr .name {
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  transition: 0.3s;
  text-transform: uppercase;
}

/*------------------------------------------
        Inner Header Start Here
-------------------------------------------*/

.inner-header {
  background-image: url("/assets/img/hero-bg.jpg");
  background-size: cover;
  padding-top: 36px;
  padding-bottom: 104px;
}

.inner-header .container .nave {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.inner-header .container .nave .ul-items {
  display: flex;
  align-items: center;
}

.inner-header .container .nave .ul-items .li-items:not(:last-child) {
  margin-right: 40px;
}

.inner-header .container .nave .ul-items .li-items .a-items {
  color: rgba(0, 0, 0, 0.65);
  transition: 0.3s;
}

.inner-header .container .nave .ul-items .li-items .a-items:hover {
  color: #000000;
}

.inner-header .container .nave .logo-otr {
  position: absolute;
  top: 1px;
  left: 583px;
}

.inner-header .container .heading-H2 {
  text-align: center;
  color: #000000;
  padding-top: 100px;
}

/*------------------------------------------
        Inner Header End Here
-------------------------------------------*/

/*------------------------------------------
     Contact  Start Here
-------------------------------------------*/

.contact .container .row-custom {
  padding-top: 128px;
  padding-bottom: 128px;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .contact .container .row-custom .content-otr {
    padding-bottom: 60px;
  }
}

.contact .container .row-custom .content-otr .content-innr .heading-H2 {
  color: #000000;
}

.contact .container .row-custom .content-otr .content-innr .heading-M {
  color: rgba(0, 0, 0, 0.65);
  padding-top: 28px;
  padding-bottom: 40px;
}

.contact .container .row-custom .content-otr .content-innr .input-otr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  width: 100%;
}

.contact .container .row-custom .content-otr .content-innr .input-otr:hover {
  border-bottom: 1px solid #000000;
}

.contact .container .row-custom .content-otr .content-innr .input-otr .input-innr {
  width: 100%;
  padding-bottom: 16px;
  color: #000000;
  border: none;
}

.contact .container .row-custom .content-otr .content-innr .input-otr .input-innr:focus {
  outline: none;
  box-shadow: none;
}

.contact .container .row-custom .content-otr .content-innr .input-otr .input-innr::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.contact .container .row-custom .content-otr .content-innr .input-otr2 {
  margin-top: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  width: 100%;
}

.contact .container .row-custom .content-otr .content-innr .input-otr2:hover {
  border-bottom: 1px solid #000000;
}

.contact .container .row-custom .content-otr .content-innr .input-otr2 .input-innr {
  width: 100%;
  padding-bottom: 16px;
  color: #000000;
  border: none;
}

.contact .container .row-custom .content-otr .content-innr .input-otr2 .input-innr:focus {
  outline: none;
  box-shadow: none;
}

.contact .container .row-custom .content-otr .content-innr .input-otr2 .input-innr::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.contact .container .row-custom .content-otr .content-innr .action-otr {
  display: flex;
  padding-top: 36px;
}

.contact .container .row-custom .content-otr .content-innr .action-otr .btn-theme {
  padding: 17px 40px;
}

/*------------------------------------------
     Contact  End Here
-------------------------------------------*/

