/*=====================================
        Color Variable's
=====================================*/

    $Box-Shadow:0px 50px 75px rgba(0, 0, 0, 0.25);
    $whatsapp-color: #3fbb4e;
    $background:#E5E5E5;
    $black-1:rgba(0, 0, 0, 1);
    $black:#000000;
    $black-65:rgba(0, 0, 0, 0.65);
    $black-4:rgba(0, 0, 0, 0.4);
    $white-Light: rgba(255, 255, 255, 1);
    $White:#FFFFFF;
    $black05:rgba(0, 0, 0, 0.05);

   
  



/*=====================================
        Font Size of Variable's
=====================================*/
    $Font-size-72px: 72px;
    $Font-size-48px: 48px; 
    $Font-size-20px: 20px;
    $Font-size-18px: 18px;
    $Font-size-16px: 16px;
    $Font-size-24px: 24px;



/*=====================================
     Variable's Line-Height
=====================================*/
    $line-height-32px: 32px;
    $line-height-28px: 28px;
    $line-height-26px: 26px;
    $line-height-24px: 24px;


/*=====================================
             Font-family
=====================================*/
  
$font-family-Syne-Medium :'Syne-Medium';
$font-family-Syne-Bold:'Syne-Bold';
$font-family-Syne-Medium:'Syne-Medium';
$font-family-Syne-Regular:'Syne-Regular';
$font-family-Syne-ExtraBold:'Syne-ExtraBold';
